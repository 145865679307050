import { IconButton } from "@instructure/ui";
import { IconArrowOpenEndLine, IconArrowOpenStartLine } from "@instructure/ui-icons";

import { msg } from "@lingui/macro";
import { useLingui } from "@lingui/react";
import { FC, useRef, useState } from "react";
import { EMPTY_ROWS, ERROR, TABLE } from "../../../../../../Constants/constants";
import PinButton from "../../PinButton";
import { ChartTitle } from "./ChartTitle/ChartTitle";
import { DropdownMenu } from "./DropdownMenu/DropdownMenu";
import { VisualizationTypesEnum } from "../../../../../../context/pinboard/pinboard.i";

interface WhiteBoardHeaderProps {
  loading: boolean;
  results: any;
  allResults: any;
  updateTitle: (title: string) => void;
  handleNavigation: (direction: string) => void;
  totalRows: number;
  chartType: VisualizationTypesEnum;
  openPinboardModal: boolean;
  setOpenPinboardModal: (open: boolean) => void;
  setSqlTrayOpen: (open: boolean) => void;
  setFullScreenOpen: (open: boolean) => void;
}

export const WhiteBoardHeader: FC<WhiteBoardHeaderProps> = ({
  loading,
  results,
  allResults,
  updateTitle,
  handleNavigation,
  totalRows,
  chartType,
  openPinboardModal,
  setOpenPinboardModal,
  setSqlTrayOpen,
  setFullScreenOpen,
}) => {
  const { _ } = useLingui();
  const prevButtonRef = useRef<IconButton>(null);
  const nextButtonRef = useRef<IconButton>(null);
  const [announcement, setAnnouncement] = useState<string>("");

  const handleNavigationWithFocus = (direction: string) => {
    handleNavigation(direction);
    
    // Move focus and announce when reaching first/last result
    if (direction === "right" && results?.id === allResults[allResults.length - 2]?.id) {
      prevButtonRef.current?.focus();
      setAnnouncement(_(msg`No more results to the right`));
    } else if (direction === "left" && results?.id === allResults[1]?.id) {
      nextButtonRef.current?.focus();
      setAnnouncement(_(msg`No more results to the left`));
    } else {
      setAnnouncement(""); // Clear announcement for normal navigation
    }
  };

  const invalidState =
    results?.visualisation === EMPTY_ROWS ||
    results?.visualisation === ERROR ||
    chartType === EMPTY_ROWS ||
    chartType === ERROR ||
    (chartType === TABLE && totalRows === 0);

  return (
    <>
      <div 
        aria-live="polite" 
        className="sr-only" 
        role="status"
      >
        {announcement}
      </div>

      <div className="flex items-center justify-between p-[6px]">
        <div className="flex">
          <IconButton
            ref={prevButtonRef}
            size="small"
            margin="small"
            renderIcon={IconArrowOpenStartLine}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Navigate Left`)}
            onClick={() => handleNavigationWithFocus("left")}
            disabled={loading || results?.id === allResults[0]?.id}
          />

          <IconButton
            ref={nextButtonRef}
            size="small"
            margin="small"
            renderIcon={IconArrowOpenEndLine}
            withBackground={false}
            withBorder={false}
            screenReaderLabel={_(msg`Navigate Right`)}
            onClick={() => handleNavigationWithFocus("right")}
            disabled={loading || results?.id === allResults[allResults.length - 1]?.id}
          />
        </div>
        <div className="flex flex-1">
          <ChartTitle
            results={results}
            updateTitle={updateTitle}
            disabled={loading || !results?.sql || invalidState}
          />
        </div>
        <div className="flex">
          {results?.error?.name === undefined && (
            <div className="flex gap-3">
              <PinButton
                isOpen={openPinboardModal}
                setIsOpen={setOpenPinboardModal}
                chartType={chartType}
                disabled={loading || invalidState}
                onClick={() => setOpenPinboardModal(true)}
              />
              <DropdownMenu
                setSqlTrayOpen={setSqlTrayOpen}
                disabled={loading}
                invalidState={invalidState}
                setFullScreenOpen={setFullScreenOpen}
              />
            </div>
          )}
        </div>
      </div>
    </>
  );
};
